/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import Title from '../../components/SEO/Title'
import Cast from '../../interfaces/Cast'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  cast: Cast[] | null
}

export default function CharacteresTab({ title, cast }: Props) {

  return (
    <>
      <Title value={`${title} - Elenco`} />
      <Box>
        {
          cast && cast?.length !== 0 ?
            cast.map((cast: any) =>
              <ListItem key={Math.random()}>
                <ListItemText
                  primary={<Link
                    key={Math.random()}
                    to={`/filmes/${cast.slug}`}
                    style={{ textDecoration: 'none', color: 'blue' }}
                  >
                    {cast.title}
                  </Link>}
                  secondary={cast.character}
                />
              </ListItem>
            ) :
            <ListItemText primary='Não Encontrado' />
        }
        {cast ? null : <Typography color='red'>Não encontrado.</Typography>}
      </Box>
    </>
  )
}
