import { useState, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useMovie } from '../../context/MovieContext'
import { BoxMenuStyle, BoxStyle, ButtonMenuStyle, LoginButton, MenuStyle, TitleMobile, TitleStyle } from './style'

interface Links {
  path: string
  label: string
}

const pages: Links[] = [
  { path: '/', label: 'Inicio' },
  { path: '/filmes', label: 'Filmes' },
  { path: '/pessoas', label: 'Pessoas' },
  { path: 'https://calculadora.wikinerd.com.br/', label: 'Calculadora de Streaming' },
]

export default function Header() {
  const { userData, removeUserData } = useMovie()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const settings: Links[] = [
    { path: '/perfil', label: 'Perfil' },
    { path: '/conta', label: 'Conta' },
  ]

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position='static'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Typography
            variant='h6'
            noWrap
            component={Link}
            to='/'
            sx={{
              ...TitleStyle,
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            WikiNerd
          </Typography>
          <Box sx={BoxStyle}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={MenuStyle}
            >
              {pages.map((page: Links, index: number) => (
                <Link
                  key={index}
                  to={page.path}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign='center'>{page.label}</Typography>
                  </MenuItem>
                </Link>

              ))}
            </Menu>
          </Box>
          <Typography
            variant='h6'
            noWrap
            component={Link}
            to='/'
            sx={{
              ...TitleMobile,
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            WikiNerd
          </Typography>
          <Box sx={BoxMenuStyle}>
            {
              pages.map((page: Links, index: number) => (
                <Link
                  key={index}
                  to={page.path}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={ButtonMenuStyle}
                  >
                    {page.label}
                  </Button>
                </Link>
              ))
            }
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {
              userData ?
                <>
                  <Tooltip title='Open settings'>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={userData.name} src='/static/images/avatar/2.jpg' />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id='menu-appbar'
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {
                      settings.map((setting: Links, index: number) => (
                        <Link
                          key={index}
                          to={setting.path}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <MenuItem
                            key={setting.label}
                            onClick={handleCloseUserMenu}
                          >
                            <Typography
                              color='primary'
                              textAlign='center'
                            >
                              {setting.label}
                            </Typography>
                          </MenuItem>
                        </Link>
                      ))
                    }

                    {
                      userData ?
                        <MenuItem
                          key='sair'
                          onClick={() => {
                            handleCloseUserMenu()
                            removeUserData()
                          }}
                        >
                          <Typography
                            color='primary'
                            textAlign='center'
                          >
                            Sair
                          </Typography>
                        </MenuItem>
                        : null
                    }
                  </Menu>
                </>
                :
                <>
                  <Button variant='text' color='inherit' sx={LoginButton} component={Link} to='/login'>Logar</Button>
                  <Button variant='outlined' color='inherit' component={Link} to='/registrar'>Registrar</Button>
                </>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
