import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { green, red, grey, yellow, blue, orange } from '@mui/material/colors';
import { AvatarGroup, Box, CircularProgress, Divider, ListItemIcon, ListSubheader, Menu, MenuItem, Tooltip } from '@mui/material';
import Movie, { BasicMovie } from '../../interfaces/Movie';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import TmdbRating from '../Rateds/Tmdb';
import ImdbRating from '../Rateds/Imdb';
import { BookmarkAdded, BookmarkAddOutlined, BookmarkAdd, BookmarkAddedOutlined, ThumbUp, ThumbDown, Favorite, FavoriteBorder, ThumbDownOffAlt, ThumbUpOffAlt, BookmarkRemoveOutlined, MoreVert, Share, ExpandMore } from '@mui/icons-material';
import { useMovie } from '../../context/MovieContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeMovieList, updateMovieList } from '../../api';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMoreCard = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }: { expand: boolean }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }: { expand: boolean }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

interface Props {
  movie: Movie | BasicMovie
}

const getTypeProvider = (type: string): string => {
  const dePara: Record<string, string> = {
    flatrate: 'Streaming',
    rent: 'Alugar',
    buy: 'Comprar',
  };

  return dePara[type] || 'Desconhecido';
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  sortedTypes: string[]
  groupedProviders: any
}

export default function BoxMovie({ movie }: Props) {
  const navigate = useNavigate()
  const location = useLocation();

  const { userData, removeUserData } = useMovie()
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getColorCertification = (certification: string | undefined) => {
    switch (certification) {
      case 'L':
        return green[500];
      case '10':
        return blue[500];
      case '12':
        return yellow[600];
      case '14':
        return orange[600];
      case '16':
        return red[500];
      case '18':
        return '#000';
      default:
        return grey[500];
    }
  }


  function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, selectedValue, open, groupedProviders, sortedTypes } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
      if (location.pathname !== '/filmes') {
        navigate(`/filmes?providers=${value}`);
      }
      onClose(value);
    };

    return (
      <Dialog onClose={handleClose} open={open} sx={{
        '& .MuiDialog-paper': {
          width: '400px',
        },
      }}>
        <List sx={{ pt: 0 }}>
          {sortedTypes.map((type) => (
            <React.Fragment key={type}>
              <ListSubheader>{getTypeProvider(type)}</ListSubheader>
              {groupedProviders[type]
                .sort((a: any, b: any) => a.display_priority - b.display_priority)
                .map((provider: any, index: any) => (
                  <ListItem disablePadding key={index}>
                    <ListItemButton onClick={() => handleListItemClick(provider.name)}>
                      <ListItemAvatar>
                        <Avatar
                          alt={provider.name}
                          src={`https://image.tmdb.org/t/p/w500${provider.logo_path.tmdb ?? ''}`}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={`${provider.name}`} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </React.Fragment>
          ))}
        </List>

      </Dialog>
    );
  }

  const AvatarGroupComponent = (input: string) => {
    const providers = JSON.parse(input);

    // Organizando os providers por tipo (flatrate, rent, buy)
    const typeOrder = ['flatrate', 'rent', 'buy'];

    const groupedProviders = providers.reduce((acc: any, provider: any) => {
      const { name, type, logo_path, display_priority } = provider;

      if (name && type && logo_path?.tmdb && display_priority != null) {
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(provider);
      }
      return acc;
    }, {});

    const sortedTypes = Object.keys(groupedProviders).sort(
      (a, b) => typeOrder.indexOf(a) - typeOrder.indexOf(b)
    );

    return (
      <div style={{ cursor: 'pointer' }}>
        <SimpleDialog
          selectedValue={''}
          open={open}
          onClose={handleClose}
          sortedTypes={sortedTypes}
          groupedProviders={groupedProviders}
        />
        <AvatarGroup max={4} onClick={handleClickOpen}>
          {sortedTypes.map((type) =>
            groupedProviders[type]
              .sort((a: any, b: any) => a.display_priority - b.display_priority)
              .map((provider: any, index: any) => (
                <Tooltip key={index} title={`${provider.name} - ${getTypeProvider(type)}`}>
                  <Avatar
                    alt={provider.name}
                    src={`https://image.tmdb.org/t/p/w500${provider.logo_path.tmdb ?? ''}`}
                  />
                </Tooltip>
              ))
          )}
        </AvatarGroup>
      </div>
    );
  };

  const handleShare = async (title: string, url: string) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `WikiNerd - ${title}`,
          text: 'Confira este site incrível que encontrei!',
          url: `${window.location.origin}/${url}`,
        });
      } catch (error) {
        console.error('Erro ao compartilhar:', error);
      }
    } else {
      alert('A API de compartilhamento não é suportada neste navegador.');
    }
  };

  const posterPath = movie?.poster_path;
  const rateds = movie?.rateds;

  const [open, setOpen] = React.useState(false);
  const [loadMovieList, setLoadMovieList] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const changeMovieList = (movieId: string, status: string, feedback?: string) => {
    if (!userData) {
      navigate('/login')
      return;
    }
    setLoadMovieList(true)
    const token = userData && typeof userData === 'object' ? userData.access_token : null
    updateMovieList(movieId, status, feedback, null, token)
      .then(async (result) => {
        movie.status = status
        movie.feedback = feedback
        setLoadMovieList(false)
      })
      .catch((err) => {
        console.error('err', err)
        setLoadMovieList(false)
        if (err.response.status === 401) {
          removeUserData()
        }
      })
    handleCloseMenu();
  };
  const deleteMovieList = (movieId: string) => {
    if (!userData) {
      navigate('/login')
      return;
    }
    setLoadMovieList(true)
    const token = userData && typeof userData === 'object' ? userData.access_token : null
    removeMovieList(movieId, token)
      .then(async (result) => {
        movie.status = null
        movie.feedback = null
        setLoadMovieList(false)
      })
      .catch((err) => {
        console.error('err', err)
        setLoadMovieList(false)
        if (err.response.status === 401) {
          removeUserData()
        }
      })
    handleCloseMenu();
  };

  return (
    <Card sx={{ maxWidth: 300 }}>
      <CardHeader
        avatar={
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: getColorCertification(movie?.certification),
              fontWeight: 'bold',
              width: 30,
              height: 30,
            }}
            aria-label="Classificação Indicativa"
          >
            {movie?.certification || '-'}
          </Avatar>
        }
        action={
          <div>
            <IconButton id="basic-button"
              aria-controls={openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={handleClickMenu}
              disabled={loadMovieList}
            >
              {loadMovieList ? <CircularProgress size="30px" /> : <MoreVert />}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => changeMovieList(movie.id, 'want_to_watch')}>
                <ListItemIcon>
                  {movie?.status && movie?.status === 'want_to_watch' ? <BookmarkAdd fontSize="small" color='primary' /> : <BookmarkAddOutlined fontSize="small" />}
                </ListItemIcon>
                <ListItemText>Quero assistir</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => changeMovieList(movie.id, 'watched')}>
                <ListItemIcon>
                  {movie?.status && movie?.status === 'watched' ? <BookmarkAdded fontSize="small" color='primary' /> : <BookmarkAddedOutlined fontSize="small" />}
                </ListItemIcon>
                <ListItemText>Já Assisti</ListItemText>
              </MenuItem>
              {
                movie?.status &&
                movie?.status === 'watched' &&
                <Divider />
              }
              {
                movie?.status &&
                movie?.status === 'watched' &&
                <MenuItem onClick={() => changeMovieList(movie.id, 'watched', 'liked')}>
                  <ListItemIcon>
                    {movie?.feedback && movie?.feedback === 'liked' ? <ThumbUp fontSize="small" color='primary' /> : <ThumbUpOffAlt fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText>Gostei</ListItemText>
                </MenuItem>
              }
              {
                movie?.status &&
                movie?.status === 'watched' &&
                <MenuItem onClick={() => changeMovieList(movie.id, 'watched', 'not_like')}>
                  <ListItemIcon>
                    {movie?.feedback && movie?.feedback === 'not_like' ? <ThumbDown fontSize="small" color='primary' /> : <ThumbDownOffAlt fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText>Não gostei</ListItemText>
                </MenuItem>
              }
              {
                movie?.status &&
                movie?.status === 'watched' &&
                <MenuItem onClick={() => changeMovieList(movie.id, 'watched', 'favorite')}>
                  <ListItemIcon>
                    {movie?.feedback && movie?.feedback === 'favorite' ? <Favorite fontSize="small" color='primary' /> : <FavoriteBorder fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText>Favorito</ListItemText>
                </MenuItem>
              }
              {
                movie?.status &&
                movie?.status !== null &&
                <Divider />
              }
              {
                movie?.status &&
                movie?.status !== null &&
                <MenuItem onClick={() => deleteMovieList(movie.id)}>
                  <ListItemIcon>
                    <BookmarkRemoveOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Remover</ListItemText>
                </MenuItem>
              }
            </Menu>
          </div>
        }
        title={
          <Tooltip title={movie?.title} arrow placement="bottom-start">
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}>
              {movie?.title}
            </span>
          </Tooltip>
        }
        subheader={
          <Moment locale='pt-br' format="DD/MMM/YYYY">
            {movie?.release_date}
          </Moment>
        }
        titleTypographyProps={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
          width: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        subheaderTypographyProps={{
          textAlign: 'center'
        }}
      />
      <CardMedia
        onClick={() => navigate(`/filmes/${movie.slug}`)}
        component="img"
        image={posterPath.tmdb ? `https://www.themoviedb.org/t/p/w600_and_h900_bestv2/${posterPath.tmdb}` : './../img/not_loaded.png'}
        alt={movie?.title}
        sx={{
          borderRadius: '10px',
          margin: 'auto',
          height: '330px',
          width: '220px',
          display: 'block',
          cursor: 'pointer',
          '&:hover': {
            filter: 'opacity(0.9)',
          },
        }}
      />
      <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <IconButton aria-label="share" onClick={() => handleShare(movie.title, `filmes/${movie.slug}`)}>
          <Share />
        </IconButton>
        {movie?.providers && <Box justifyItems='center'>{AvatarGroupComponent(movie?.providers)}</Box>}
        <ExpandMoreCard
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMore />
        </ExpandMoreCard>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="subtitle2">Sinopse</Typography>
          <Typography variant='body2' textAlign='justify'>{movie?.overview || 'Sinopse não disponível.'}</Typography>
          <Box marginTop={4} display='flex' justifyContent='space-around'>
            {/* <Box justifyItems='center'>
              <ImdbRating rating={6.2} votes='33 mil' />
              <Typography component="legend" fontSize='12px'>IMDB</Typography>
            </Box> */}
            <Box justifyItems='center'>
              <TmdbRating value={rateds.tmdb ? rateds.tmdb * 10 : 0} />
              <Typography component="legend" fontSize='12px'>TMDB</Typography>
            </Box>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}
