import {
  createContext,
  ReactNode,
  useContext,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { CacheStorage } from '../helpers/CacheStorage'
import User from '../interfaces/User'

interface Props {
  children: ReactNode
}

const MovieContext = createContext<any>({})

const MovieProvider = ({ children }: Props) => {
 
  const navigate = useNavigate()

  const dataStorage = CacheStorage.get('wikinerd-usr-data')
  const initialData = dataStorage ? JSON.parse(dataStorage) : false
  const [userData, setUserData] = useState<User | boolean>(initialData)

  const storeUserData = (user: User) => {
    setUserData(user)
    CacheStorage.set('wikinerd-usr-data', JSON.stringify(user))
  }
  const removeUserData = () => {
    setUserData(false)
    CacheStorage.delete('wikinerd-usr-data')
    navigate('/login')
  }

  const valueData = {
    userData,
    storeUserData,
    removeUserData
  }

  return <MovieContext.Provider value={valueData} >{children}</MovieContext.Provider>
}

function useMovie() {
  const context = useContext(MovieContext)

  if (!context) {
    throw new Error('useContext must be used in MovieProvider')
  }

  return context
}

export { MovieContext, MovieProvider, useMovie }
