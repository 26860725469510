import { Box, Typography, Link } from '@mui/material';
import Title from '../../components/SEO/Title';

export default function ContactUsPage() {
  const pageTitle = 'Contate-nos';

  return (
    <>
      <Title value={pageTitle} />
      <Box component="section" sx={{ textAlign: 'center', mt: 4, mb: 4 }}>
        <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
          {pageTitle}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Email: <Link href="mailto:contato@wikinerd.com.br">contato@wikinerd.com.br</Link>
        </Typography>
        <Typography variant="body1">
          Instagram:{" "}
          <Link 
            href="https://instagram.com/thewikinerd" 
            color="inherit" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            @TheWikiNerd
          </Link>
        </Typography>
      </Box>
    </>
  );
}
