import { Card, CardContent, Typography } from '@mui/material'
import ReactPlayer from 'react-player'
import Title from '../../components/SEO/Title'
import Video from '../../interfaces/Video'
import Grid from '@mui/material/Grid2';
import Moment from 'react-moment';

interface Props {
  videos: Array<Video>
  title: string
}

export default function VideosTab({ videos, title }: Props) {
  return (
    <>
      <Title value={`${title} - Videos`} />
      <Grid container spacing={2}>
        {
          videos.length !== 0 ?
            videos.map((video: Video) => {
              return (
                <Grid size={{ xs: 12, sm: 6, md: 4 }} key={video.id}>
                  <Card sx={{ minHeight: '400px' }}>
                    <CardContent>
                      <Typography variant="h6">{video.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        <Moment format='DD/MM/YYYY'>{video.published_at}</Moment>
                      </Typography>
                      <ReactPlayer
                        url={`https://www.youtube.com/watch?v=${video.key}`}
                        controls
                        width="100%"
                        sx={{ minHeight: '400px' }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )
            })
            :
            <Typography
              color='red'
              align='center'
              marginTop='10px'
            >
              Nenhum video até o momento.
            </Typography>
        }
      </Grid>
    </>
  )
}
