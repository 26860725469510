import { useEffect, useState } from 'react'
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { getMovie, getMovieCollection } from '../../api'
import Movie from '../../interfaces/Movie'
import { Box } from '@mui/system'
import { Ballot, Collections, RecentActors, SupervisorAccount, VideoLibrary } from '@mui/icons-material'
import Moment from 'react-moment'
import Photos from './PhotosTab'
import Videos from './VideosTab'
import BoxItem from '../../components/BoxItem'
import Money from '../../components/Money'
import { useMovie } from '../../context/MovieContext'
import Cast from '../../interfaces/Cast'
import SingleLineImageList from '../../components/SingleLineImageList'
import BoxMovie from '../../components/BoxMovie'
import BoxPerson from '../../components/BoxPerson'
import CastTab from './CastTab'
import PersonListSkeleton from '../../components/PersonListSkeleton'
import CrewTab from './CrewTab'
import Title from '../../components/SEO/Title'
import FacebookOpenGraph from '../../components/SEO/FacebookOpenGraph'
import LdJson from '../../components/SEO/LdJson'
import Crew from '../../interfaces/Crew'
import Collection from '../../interfaces/Collection'
import { BoxImageNotLoadedStyle } from '../../components/BoxMovie/style'
import ExternalId from '../../interfaces/ExternalId'
import Country from '../../interfaces/Country'

interface IdName {
  id: string,
  name: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export default function MoviePage() {
  let { path } = useParams()
  const [movie, setMovie] = useState<Movie | null>(null)
  const [collection, setCollection] = useState<Array<Collection> | null>(null)
  const [genres, setGenres] = useState<string | null>(null)
  const [keywords, setKeywords] = useState<string | null>(null)
  // const [directors, setDirectors] = useState<any>(null)
  // const [writers, setWriters] = useState<string | null>(null)
  // const [producers, setProducers] = useState<string | null>(null)
  const [fullCast, setFullCast] = useState<Array<Cast> | null>(null)
  const [mainCast, setMainCast] = useState<Array<Cast> | null>(null)
  const [fullCrew, setFullCrew] = useState<Array<Crew> | null>(null)
  const { userData } = useMovie()
  const [load, setLoad] = useState<boolean>(false)
  const stylePoster = load ? { height: ['500px', '400px'], margin: '2px' } : { display: 'none' }

  const returnList = (items: Array<IdName>) => {
    return items.map((item: IdName) => item.name).join(', ')
  }

  const urlExternalIds = (data: ExternalId) => {
    const urls: Record<string, string> = {
      imdb: `https://www.imdb.com/pt/title/${data.external_id}`,
      tmdb: `https://www.themoviedb.org/movie/${data.external_id}`,
      facebook: `https://www.facebook.com/${data.external_id}`,
      instagram: `https://www.instagram.com/${data.external_id}`,
      twitter: `https://www.twitter.com/${data.external_id}`,
      wikidata: `https://www.wikidata.org/wiki/${data.external_id}`,
    }

    return urls[data.platform] || '/';
  }

  useEffect(() => {
    (async () => {
      if (path) {
        let result = await getMovie(path)

        if (result?.genres) {
          setGenres(returnList(result.genres))
        }
        if (result?.keywords) {
          setKeywords(result?.keywords?.map(
            (item: IdName) => <Chip sx={{ margin: '2px' }} size='small' label={item.name} key={item.name} />
          ))
        }
        // setDirectors(result.crew.filter((crew: Crew) => crew.job === 'Director').map((crew: Crew) => crew.person?.name).join(', '))
        // setWriters(result.crew.filter((crew: Crew) => ['Screenplay', 'Writer', 'Story'].includes(crew.job)).map((crew: Crew) => `${crew.person?.name}(${crew.job})`).join(', '))
        // setProducers(result.crew.filter((crew: Crew) => crew.job === 'Producer').map((crew: Crew) => crew.person?.name).join(', '))
        setMovie(result)
        if (result.collection_id) {
          let resultCollection = await getMovieCollection(result.collection_id)
          resultCollection = resultCollection.filter((movie: Movie) => movie.id !== result.id)

          setCollection(resultCollection)
        }
        setFullCast(result?.cast)
        setMainCast(result?.cast.slice(0, 10))

        setFullCrew(result?.crew)
      }
    })()
  }, [path, userData])
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {
        movie &&
        <>
          <Title value={movie.title} />
          <FacebookOpenGraph title={movie.title} id={movie.id} slug={movie.slug} imagePath={movie?.poster_path?.tmdb} type='movie' />
          <LdJson data={movie} />
        </>
      }

      <Tabs centered value={value} onChange={handleChange} aria-label='icon label tabs example'>
        <Tab
          icon={<Ballot />}
          label='SOBRE'
        />
        <Tab
          icon={<SupervisorAccount />}
          label='ELENCO'
        />
        <Tab
          icon={<RecentActors />}
          label='EQUIPE'
        />
        <Tab
          icon={<Collections />}
          label='POSTERS'
        />
        <Tab
          icon={<VideoLibrary />}
          label='VIDEOS'
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {
              movie ?
                <>
                  {
                    !load &&
                    <Box
                      sx={BoxImageNotLoadedStyle}
                      key={movie.slug}
                      component='img'
                      src='./../img/not_loaded.png'
                      alt={`Poster do filme ${movie.title}`}
                      title={`Poster do filme ${movie.title} não carregou`}
                      width='120px'
                    />
                  }

                  <Box
                    sx={stylePoster}
                    component='img'
                    alt={`Poster do filme ${movie?.title}`}
                    src={movie?.poster_path.tmdb ? `https://www.themoviedb.org/t/p/w600_and_h900_bestv2/${movie?.poster_path.tmdb}` : './../img/not_loaded.png'}
                    onLoad={() => setLoad(true)}
                  />
                  {/* <ToggleListButtons movieId={movie.id} statusAtual={movie.user_status} /> */}
                </>
                :
                <>
                  <Skeleton width={266} height={400} variant="rectangular" animation="wave" sx={{ margin: '2px' }} />
                  <Skeleton width={196} height={40} variant="rectangular" animation="wave" sx={{ margin: '2px' }} />
                </>
            }
          </Box>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexDirection: {
                  xs: 'column',
                  lg: 'row'
                },
              }}
            >
              {
                movie ?
                  <>
                    <Typography variant='h5' component='h1'>
                      {movie?.title}
                    </Typography>
                    <Typography variant='subtitle1' component='h2' sx={{ marginLeft: '2px' }}>
                      ({movie?.original_title})
                    </Typography>
                  </>
                  :
                  <Skeleton width={210} height={30} variant="rectangular" />
              }
            </Box>
            {
              movie ?
                <Typography variant='caption' component='h2'>
                  <Moment format='DD/MM/YYYY'>{movie?.release_date}</Moment>
                  {` | ${movie?.release_dates[0].certification ?? '-'} | ${movie?.runtime ?? 0}m | `}
                  {genres}
                </Typography>
                :
                <Skeleton width={400} height={20} variant="rectangular" sx={{ marginTop: '2px' }} />
            }

            <Typography
              variant='subtitle1'
              sx={{ fontStyle: 'italic', display: 'flex', justifyContent: 'center', margin: '5px' }}
            >
              {movie?.tagline}
            </Typography>

            {
              movie ?
                <>
                  <BoxItem label='Sinopse' text={movie?.overview} />
                  <BoxItem label='Titulo original' text={movie?.original_title} />
                  <BoxItem label='Status' text={<Chip sx={{ marginRight: '2px' }} color='primary' variant='outlined' size='small' label={movie?.status} />} />
                  <BoxItem label='Linguas' text={movie?.languages.map((language: any) => language.name).join(',')} />
                  <BoxItem label='Orçamento' text={<Money value={movie?.budget} />} />
                  <BoxItem label='Faturamento' text={<Money value={movie?.revenue} />} />
                  {/* <BoxItem label='Diretor(es)' text={directors} />
                      <BoxItem label='Roteirista(s)' text={writers} />
                      <BoxItem label='Produtor(es)' text={producers} /> */}
                  <BoxItem label='Palavras chaves' text={keywords} />
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 2 }}>
                    {
                      movie.countries.map((country: Country) => {
                        return (
                          <IconButton aria-label={country.name}>
                            <Tooltip title={country.name}>
                              <Avatar
                                variant="square"
                                src={`https://flagcdn.com/w40/${country.id.toLowerCase()}.png`}
                                alt={`Bandeira ${country.name}`}
                                sx={{ width: 25, height: 20 }}
                              >
                                🌍
                              </Avatar>
                            </Tooltip>
                          </IconButton>
                        )
                      })
                    }
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2, marginTop: 4 }}>
                    {
                      movie.external_ids.map((external: ExternalId) => {
                        return (
                          <IconButton aria-label={external.platform.toUpperCase()} onClick={() => window.open(urlExternalIds(external))}>
                            <Tooltip title={external.platform.toUpperCase()}>
                              <Avatar alt={external.platform} src={`/img/logos/${external.platform}.svg`} />
                            </Tooltip>
                          </IconButton>
                        )
                      })
                    }
                  </Box>
                </> :
                Array(8).fill(null).map((value, index) => <Skeleton key={index} width={600} height={20} variant="rectangular" sx={{ marginTop: '4px' }} />)
            }
          </CardContent>
        </Card>
        {
          <SingleLineImageList title='Elenco Principal'>
            {
              mainCast && mainCast.length !== 0 ?
                mainCast.map((cast: Cast) => <BoxPerson key={Math.random()} id={cast.id} name={cast.name} slug={cast.slug} profile_path={cast.profile_path} character={cast.pivot.character} />) :
                Array(10).fill(null).map((_, index) => <PersonListSkeleton key={index} />)
            }
          </SingleLineImageList>
        }

        {
          movie &&
          collection &&
          collection.length !== 0 &&
          <SingleLineImageList title={`${collection[0].name}`}>
            {collection.map((movie: any) => <BoxMovie key={movie?.id} movie={movie} />)}
          </SingleLineImageList>
        }

      </TabPanel>
      <TabPanel value={value} index={1}>
        {movie && <CastTab title={movie.title} fullCast={fullCast} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {movie && <CrewTab title={movie.title} fullCrew={fullCrew} />}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {movie?.images && <Photos photos={movie?.images} title={movie.title} />}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {movie?.videos && <Videos videos={movie?.videos} title={movie.title} />}
      </TabPanel>
    </>
  )
}
