import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material'
import { FormEvent, useState } from 'react'
import { update, updatePassword } from '../../api'
import { useMovie } from '../../context/MovieContext'
import Message from '../../interfaces/Message'
import { Navigate } from 'react-router-dom'
import Title from '../../components/SEO/Title'
import { jwtDecode } from "jwt-decode"

export default function AccountPage() {

  const pageTitle = 'Conta'

  const { storeUserData, userData } = useMovie()

  let decodedToken = { name: "", email: "", username: "" };

  const [message, setMessage] = useState<Message | null>(null)

  if (userData?.access_token) {
    try {
      decodedToken = jwtDecode(userData.access_token);
    } catch (error) {
      setMessage({ severity: 'error', text: 'Token inválido' })
      console.error('Token inválido', error);
    }
  }

  const [name, setName] = useState<string>(decodedToken.name)
  const [email, setEmail] = useState<string>(decodedToken.email)
  const [username, setUsername] = useState<string>(decodedToken.username)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  if (!userData) {
    return <Navigate to="/login" replace />
  }

  const token = userData.access_token

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    update({ name, email, username, token })
      .then((response) => {
        const newUser = { ...response.data, token }
        storeUserData(newUser)
        setMessage({ severity: 'success', text: 'Dados atualizados com sucesso' })
      })
      .catch(err => setMessage({ severity: 'error', text: err.response.data.message }))
  }

  const handleSubmitPassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (password !== passwordConfirm) {
      setMessage({ severity: 'error', text: 'As senhas não correspondem' })
      return false
    }
    updatePassword(password, passwordConfirm, token)
      .then(() => {
        setMessage({ severity: 'success', text: 'Senha atualizada com sucesso' })
        setPassword('')
        setPasswordConfirm('')
      })
      .catch(err => {
        setMessage({ severity: 'error', text: err.response.data.message })
      })
  }

  return (
    <>
      <Title value={pageTitle} />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {message && <Alert severity={message.severity} onClose={() => setMessage(null)}>{message.text}</Alert>}
        <Typography variant='h4'>Dados básicos</Typography>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label='Nome'
                onChange={(event) => setName(event.target.value)}
                value={name}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Email'
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                type="email"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label='Username'
                onChange={(event) => setUsername(event.target.value)}
                value={username}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              mt: 3,
              mb: 2
            }}
          >
            Atualizar
          </Button>
        </Box>

        <Typography variant='h4'>Atualizar senha</Typography>
        <Box component='form' onSubmit={handleSubmitPassword}>
          <TextField
            label='Senha'
            type='password'
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            margin='normal'
            required
            fullWidth
          />
          <TextField
            label='Confirmar Senha'
            type='password'
            onChange={(event) => setPasswordConfirm(event.target.value)}
            value={passwordConfirm}
            margin='normal'
            required
            fullWidth
          />
          <Typography
            component='p'
            variant='caption'
          >
            As senhas devem conter 8 caracteres, sendo um caracter numérico, um minúsculo, um maiúsculo e um simbolo.
          </Typography>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{
              mt: 3,
              mb: 2
            }}
          >
            Atualizar
          </Button>
        </Box>
      </Box>
    </>
  )
}
