import { Helmet } from 'react-helmet'

interface Props {
  title: string
  id: string
  slug: string
  imagePath: string | null | undefined
  type: 'movie' | 'person'
}

export default function FacebookOpenGraph({ title, id, slug, imagePath, type }: Props) {
  const path = type === 'movie' ? 'filmes' : 'Pessoas'
  return (
    <Helmet>
      <meta property="og:title" content={title} />
      {type === 'movie' && <meta property="og:type" content="video.movie" />}
      <meta property="og:url" content={`https://www.wikinerd.com.br/${path}/${slug}`} />
      <meta property="og:image" content={`https://www.themoviedb.org/t/p/w220_and_h330_face/${imagePath}`} />
    </Helmet>
  )
}
