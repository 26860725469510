/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import Title from '../../components/SEO/Title'
import { Link } from 'react-router-dom'
import Crew from '../../interfaces/Crew'

interface Props {
  title: string
  crew: Crew[] | null
}

export default function CreditsTab({ title, crew }: Props) {
  const newCrew: any = []
  // eslint-disable-next-line array-callback-return
  crew && crew.map(element => {
    const find = newCrew.find((crew: Crew) => crew.id === element.id)
    if (find) {
      find.jobs.push(`${element.job}(${element.department})`)
    } else {
      newCrew.push({
        id: element.id,
        movie: element.title,
        slug: element.slug,
        jobs: [`${element.job}(${element.department})`]
      })
    }
  })
  return (
    <>
      <Title value={`${title} - Elenco`} />
      <Box>
        {
          newCrew.length !== 0 ?
            newCrew.map((crew: any) =>
              <ListItem key={crew.id}>
                <ListItemText
                  primary={
                    <Link
                      key={crew.id}
                      to={`/filmes/${crew.slug}`}
                      style={{ textDecoration: 'none', color: 'blue' }}
                    >
                      {crew.movie}
                    </Link>
                  }
                  secondary={crew.jobs.map((job: string) => job).join(', ')}
                />
              </ListItem>
            ) :
            <ListItemText primary='Não Encontrado' />
        }
        {newCrew ? null : <Typography color='red'>Não encontrado.</Typography>}
      </Box>
    </>
  )
}
