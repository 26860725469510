/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import BoxPerson from '../../../components/BoxPerson'
import { BoxPrimaryStyle } from './style'
import PersonListSkeleton from '../../../components/PersonListSkeleton'
import Title from '../../../components/SEO/Title'
import Cast from '../../../interfaces/Cast'

interface Props {
  title: string
  fullCast: Cast[] | null
}

export default function CastTab({ title, fullCast }: Props) {
  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
  const getColumns = (width: any) => {
    if (width < breakpoints.sm) {
      return 2
    } else if (width < breakpoints.md) {
      return 4
    } else if (width < breakpoints.lg) {
      return 6
    } else if (width < breakpoints.xl) {
      return 8
    } else {
      return 10
    }
  }
  const [columns, setColumns] = useState(getColumns(window.innerWidth))
  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth))
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [])

  return (
    <>
      <Title value={`${title} - Elenco`} />
      <Box sx={BoxPrimaryStyle}>
        {
          fullCast ?
            <>
              <ImageList gap={1} cols={columns}>
                {
                  fullCast.map((cast: any) =>
                    <ImageListItem key={Math.random()}>
                      <BoxPerson id={cast.id} name={cast.name} slug={cast.slug} profile_path={cast.profile_path} character={cast.pivot.character} />
                      <Divider />
                    </ImageListItem>
                  )
                }
              </ImageList>
            </> :
            <ImageList gap={1} cols={columns}>
              {
                Array(24).fill(null).map((value, index) =>
                  <ImageListItem key={index}>
                    <PersonListSkeleton />
                  </ImageListItem>
                )
              }
            </ImageList>
        }
        {fullCast ? null : <Typography color='red'>Não encontrado.</Typography>}
      </Box>
    </>
  )
}
