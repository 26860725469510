export const BoxPrimaryStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '10px 0 0 0',
}
export const BoxSecundaryStyle = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
}
export const PaperStyle = {
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  width: [300, 400],
  margin: '6px'
}
export const FormControlStyle = {
  m: 1,
  minWidth: 120
}
