import {
  Box, Link, Typography,
} from '@mui/material'
import Title from '../../components/SEO/Title'
import { BoxPrimaryStyle, LogosStyle, ParagraphStyle } from './style'

export default function AboutPage() {
  const pageTitle = 'Sobre';

  const descriptions = [
    'WikiNerd não é um site de streaming de filmes piratas.',
    'WikiNerd é um aplicativo feito para você organizar e acompanhar seus filmes favoritos. Nele, você pode registrar os filmes que deseja assistir, marcar aqueles que já viu e avaliar se gostou ou não. Além disso, pode adicionar filmes à sua lista de favoritos para fácil acesso.',
    'Fique por dentro das últimas estreias, próximos lançamentos, filmes mais populares e os mais bem avaliados, tudo em um só lugar.',
    'O aplicativo também oferece informações detalhadas sobre cada filme, como sinopse, data de lançamento, gêneros, duração, e elenco completo.',
    'Os dados e imagens são fornecidos pela API do TMDB (The Movie Database), e as informações sobre plataformas de streaming são atualizadas via JustWatch.',
  ];

  const links = [
    {
      href: 'https://www.themoviedb.org/',
      src: './img/tmdb.svg',
      alt: 'Logo TMDb',
      title: 'This product uses the TMDB API but is not endorsed or certified by TMDB',
      width: '80px',
    },
    {
      href: 'https://www.justwatch.com/',
      src: './img/justwatch.svg',
      alt: 'Logo Just Watch',
      title: 'This product uses the JustWatch but is not endorsed or certified by JustWatch',
      width: '120px',
    },
  ];

  return (
    <>
      <Title value={pageTitle} />
      <Box sx={BoxPrimaryStyle}>
        <Typography variant="h3" component="h1">
          {pageTitle}
        </Typography>

        {descriptions.map((text, index) => (
          <Typography
            key={index}
            variant={index === 0 ? 'h6' : 'body1'}
            component={index === 0 ? 'p' : 'span'}
            sx={ParagraphStyle}
          >
            {text}
          </Typography>
        ))}

        <Box sx={LogosStyle}>
          {links.map((link, index) => (
            <Link
              key={index}
              color="inherit"
              href={link.href}
              target="_blank"
              aria-label={link.alt}
              sx={{ mr: index < links.length - 1 ? 5 : 0 }}
            >
              <Box
                component="img"
                src={link.src}
                alt={link.alt}
                title={link.title}
                width={link.width}
              />
            </Link>
          ))}
        </Box>
      </Box>
    </>
  );
}
