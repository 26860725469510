import { ReactNode, useRef } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

interface Props {
  title: string;
  children: ReactNode;
}

export default function SingleLineImageList({ title, children }: Props) {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const scrollAmount = 300;
      scrollRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box sx={{ backgroundColor: '#f8f9fa', padding: '8px', position: 'relative' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: 2 }}>
        <Typography sx={{ textAlign: 'left', color: '#000' }} variant='h5' component='h2'>
          {title}
        </Typography>
        <Box>
          <IconButton onClick={() => scroll('left')}>
            <ArrowBackIos />
          </IconButton>
          <IconButton onClick={() => scroll('right')}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          gap: 2,
          padding: 2,
          maxWidth: '100%',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': { height: '8px' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#888', borderRadius: '4px' },
          '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
