import { Box, Typography } from '@mui/material';
import Title from '../../components/SEO/Title';
import { BoxPrimaryStyle } from './style';

interface FaqProps {
  question: string;
  answer: string;
}

const faqData: FaqProps[] = [
  {
    question: 'O que é o WikiNerd?',
    answer: 'O WikiNerd é um aplicativo que ajuda você a organizar e acompanhar seus filmes favoritos. Nele, você pode registrar os filmes que deseja assistir, marcar aqueles que já viu, avaliar se gostou ou não e adicioná-los à sua lista de favoritos.',
  },
  {
    question: 'Posso importar dados de log de outros serviços?',
    answer: 'Ainda não oferecemos a funcionalidade de importação. No entanto, você pode nos enviar o modelo do arquivo para análise, e avaliaremos a possibilidade de integrar essa funcionalidade no futuro.',
  },
  {
    question: 'Posso exportar meus dados?',
    answer: 'A exportação de dados ainda não está disponível, mas estamos trabalhando para implementar essa funcionalidade em breve.',
  },
  {
    question: 'O que faço quando encontro um bug?',
    answer: 'Caso encontre um bug, entre em contato conosco pelos nossos canais de atendimento. Ficaremos felizes em ajudar e resolver o problema o mais rápido possível.',
  },
];

function Paragraph({ question, answer }: FaqProps) {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle2" component="p" textAlign="justify" fontWeight="bold">
        {question}
      </Typography>
      <Typography variant="subtitle1" component="p" textAlign="justify">
        {answer}
      </Typography>
    </Box>
  );
}

export default function FaqPage() {
  const pageTitle = 'FAQ';

  return (
    <>
      <Title value={pageTitle} />
      <Typography variant="h3" component="h1" textAlign="center" sx={{ mt: 4, mb: 2 }}>
        {pageTitle}
      </Typography>
      <Box sx={BoxPrimaryStyle}>
        {faqData.map((faq, index) => (
          <Paragraph key={index} question={faq.question} answer={faq.answer} />
        ))}
      </Box>
    </>
  );
}
