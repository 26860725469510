import { useState, useMemo } from 'react'
import { ImageList, ImageListItem, ImageListItemBar, Typography, Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import Title from '../../components/SEO/Title'
import Image from '../../interfaces/Image'

interface Props {
  photos: Array<Image>
  title: string
}

export default function PhotosTab({ photos, title }: Props) {
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  const imageUrls = useMemo(() => {
    return photos.map(item => ({
      src: `https://www.themoviedb.org/t/p/original/${item.file_path}?w=248&fit=crop&auto=format`,
      srcSet: `https://www.themoviedb.org/t/p/original/${item.file_path}?w=248&fit=crop&auto=format&dpr=2 2x`,
      alt: item.file_path,
      source: item.source,
      height: item.height,
      width: item.width,
      type: item.type,
    }))
  }, [photos])

  const handleImageClick = (src: string) => {
    setSelectedImage(src)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setSelectedImage(null)
  }

  return (
    <>
      <Title value={`${title} - Posteres`} />
      {photos.length > 0 ? (
        <ImageList variant="masonry" sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {imageUrls.map(({ src, srcSet, alt, source, height, width, type }) => (
            <ImageListItem
              key={alt}
              sx={{
                width: '150px',
                height: '100%',
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick(src)}
            >
              <img
                src={src}
                srcSet={srcSet}
                alt={alt}
                loading="lazy"
                style={{
                  width: '100%',
                  height: '230px',
                  border: '1px solid #2276d2',
                  borderRadius: '8px',
                }}
                onError={(e) => (e.currentTarget.src = './../img/not_loaded.png')}
              />
              <ImageListItemBar
                title={`${type}: ${height} x ${width}`}
                subtitle={`Fonte: ${source.toUpperCase()}`}
                sx={{
                  '& .MuiImageListItemBar-title': {
                    fontStyle: 'italic',
                    fontSize: '1rem',
                    textTransform: 'capitalize',
                  },
                  '& .MuiImageListItemBar-subtitle': {
                    fontStyle: 'italic',
                    fontSize: '1rem',
                    color: 'lightgray',
                  },
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Box sx={{ marginTop: 2, textAlign: 'center' }}>
          <Typography color="red">Nenhuma imagem até o momento.</Typography>
        </Box>
      )}

      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{ width: '100%', height: '100%', borderRadius: '8px' }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
