import axios from 'axios'
import User from '../interfaces/User'

const defineHeaders = (token?: string | null) => {
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }
}

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE
})

type FetchMoviesParams = {
  endpoint: string
  currentPage?: number
  perPage?: number
  orderByField?: string | null
  orderByDirection?: string
  token?: string | null
  search?: string | null
  certification?: string | null
  providers?: string | null
  genres?: string | null
  countries?: string | null
}

type FetchPeopleParams = {
  endpoint: string
  currentPage?: number
  perPage?: number
  orderByField?: string | null
  orderByDirection?: string
  token?: string | null
  search?: string | null
  adult: boolean
}

const fetchMovies = async ({
  endpoint,
  currentPage = 1,
  perPage = 15,
  orderByField = 'release_date',
  orderByDirection = 'desc',
  token = null,
  search = null,
  certification = null,
  providers = null,
  genres = null,
  countries = null
}: FetchMoviesParams) => {
  const params = new URLSearchParams()

  params.append('page', currentPage.toString())
  params.append('per_page', perPage.toString())
  params.append('order_by_direction', orderByDirection)
  if (orderByField) params.append('order_by_field', orderByField)
  if (search) params.append('search', search)

  const appendArrayParam = (key: string, value: string | null) => {
    if (value) {
      value.split(',').forEach((item) => params.append(`${key}[]`, item))
    }
  }

  appendArrayParam('certification', certification)
  appendArrayParam('providers', providers)
  appendArrayParam('genres', genres)
  appendArrayParam('countries', countries)

  const response = await api.get(`/movies/${endpoint}?${params.toString()}`, defineHeaders(token))
  return response.data
}

export const getAllMovies = (params: Omit<FetchMoviesParams, 'endpoint'>) =>
  fetchMovies({ endpoint: '', ...params })

export const getNowPlayingMovies = (params: Omit<FetchMoviesParams, 'endpoint'>) =>
  fetchMovies({ endpoint: 'now-playing', perPage: 30, ...params })

export const getUpcomingMovies = (params: Omit<FetchMoviesParams, 'endpoint'>) =>
  fetchMovies({ endpoint: 'upcoming', ...params })

export const getTopRatedMovies = (params: Omit<FetchMoviesParams, 'endpoint'>) =>
  fetchMovies({ endpoint: 'top-rated', ...params })

const fetchPeople = async ({
  endpoint,
  currentPage = 1,
  perPage = 15,
  orderByField = 'name',
  orderByDirection = 'asc',
  token = null,
  search = null,
  adult = false,
}: FetchPeopleParams) => {
  const params = new URLSearchParams()

  params.append('page', currentPage.toString())
  params.append('per_page', perPage.toString())
  params.append('order_by_direction', orderByDirection)
  params.append('adult', String(adult))
  if (orderByField) params.append('order_by_field', orderByField)
  if (search) params.append('search', search)

  const response = await api.get(`/people/${endpoint}?${params.toString()}`, defineHeaders(token))
  return response.data
}

export const getAllPeople = (params: Omit<FetchPeopleParams, 'endpoint'>) =>
  fetchPeople({ endpoint: '', ...params })

export const updateMovieList = async (
  movieId: string,
  status: string,
  feedback: string | null = null,
  watched_date: string | null = null,
  token: string | null = null,
) => {
  const response = await api.put(
    '/users/movie',
    {
      "movie_id": movieId,
      "status": status,
      "feedback": feedback,
      "watched_date": watched_date,
    },
    defineHeaders(token)
  )

  return response.data
}

export const removeMovieList = async (movieId: string, token: string | null) => {
  const response = await api.delete(
    `/users/movie/${movieId}`,
    defineHeaders(token)
  )

  return response.data
}

export const getPersonMovies = async (id: string, token: string | null) => {
  const response = await api.get(
    `/people/${id}/movies`,
    defineHeaders(token)
  )
  return response.data
}

export const getMovie = async (slug: string) => {
  const response = await api.get(`/movies/${slug}`)
  return response.data
}

export const getPerson = async (id: string) => {
  const response = await api.get(`/people/${id}`)
  return response.data
}

export const getMovieCollection = async (id: string) => {
  const response = await api.get(
    `/movies/collection/${id}`,
  )
  return response.data
}

export const login = async (email: string, password: string) => {
  return await api.post('/login', {
    email,
    password,
    origin: 'web'
  })
}

export const register = async ({ name, email, password }: User) => {
  return await api.post('/register', {
    name,
    email,
    password,
    password_confirmation: password,
  })
}

export const update = async ({ name, email, username, token }: User) => {
  return await api.put(
    '/users',
    {
      name,
      email,
      username,
    },
    defineHeaders(token)
  )
}
export const updatePassword = async (password: string, passwordConfirm: string, token: string) => {
  return await api.put(
    '/users/password',
    {
      password,
      password_confirmation: passwordConfirm,
    },
    defineHeaders(token)
  )
}

export const movieList = async (movieId: number, status: string, token: string) => {
  return await api.post(
    '/users/movie',
    {
      id_movie: movieId,
      status,
    },
    defineHeaders(token)
  )
}

export const getUserList = async ({
  term,
  currentPage = 1,
  order = {
    field: 'popularity',
    direction: 'DESC'
  },
  filter = {
    statusList: '',
  },
  token
}: any) => {
  const status = (filter.statusList === 'not_like' || filter.statusList === 'liked' || filter.statusList === 'favorite') ? 'watched' : filter.statusList;
  const feedback = filter.statusList === 'want_to_watch' || filter.statusList === 'watched' ? '' : filter.statusList;
  const response = await api.get(
    `/users/${term}?page=${currentPage}&order=${order.field}&direction=${order.direction}${status !== '' ? `&status=${status}` : ''}${feedback !== '' ? `&feedback=${feedback}` : ''}`,
    defineHeaders(token)
  )
  return response.data
}

export const getUserListStatus = async (ids: Array<number>, token: string) => {
  if (ids.length > 0) {
    const response = await api.get(`/users/movies-list`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: { ids: ids }
    })
    return response.data
  }

  return null
}
