import React, { useEffect, useState, useCallback, FormEvent, ChangeEvent } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  faArrowDownAZ,
  faArrowDownZA,
  faArrowDown19,
  faArrowDown91,
  faCalendarDay,
  faCalendar,
  faHeart,
  faA,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backspace, RestartAlt, Search } from "@mui/icons-material";

import {
  getAllMovies,
  getNowPlayingMovies,
  getTopRatedMovies,
  getUpcomingMovies,
} from "../../api";
import Movie from "../../interfaces/Movie";
import BoxMovie from "../../components/BoxMovie";
import MovieListSkeleton from "../../components/MovieListSkeleton";
import Title from "../../components/SEO/Title";
import ReturnMovie from "../../interfaces/ReturnMovie";
import { useMovie } from "../../context/MovieContext";

import { BoxPrimaryStyle, BoxSecundaryStyle, FormControlStyle, PaperStyle } from "./style";
import "./GridList.css";

import { useSearchParams } from "react-router-dom";
import Country from "../../interfaces/Country";
import countriesData from "../../data/countries.json";
import certificationsData from "../../data/certifications.json";
import providersData from "../../data/providers.json";
import genresData from "../../data/genres.json";

const MoviesPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<string>(searchParams.get("page") || '1');
  const [query, setQuery] = useState<string>(searchParams.get("query") || "");
  const [debouncedQuery, setDebouncedQuery] = useState<string>("");
  const [result, setResults] = useState<ReturnMovie | undefined>();
  const [type, setType] = useState<string>(searchParams.get("type") || "all");
  const [order, setOrder] = useState<string>(searchParams.get("order") || "release_date-desc");
  const [certification, setCertification] = useState<string[]>(
    searchParams.getAll("certification") || []
  );
  const [providers, setProviders] = useState<string[]>(
    searchParams.getAll("providers") || []
  );
  const [genres, setGenres] = useState<string[]>(
    searchParams.getAll("genres") || []
  );
  const [countries, setCountries] = useState<string[]>(
    searchParams.getAll("countries") || []
  );
  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
  const getColumns = useCallback((width: number) => {
  if (width < breakpoints.sm) return 1;
  if (width < breakpoints.md) return 2;
  if (width < breakpoints.lg) return 3;
  if (width < breakpoints.xl) return 4;
  return 6;
}, [breakpoints.lg, breakpoints.md, breakpoints.sm, breakpoints.xl]);
  const [columns, setColumns] = useState<number>(getColumns(window.innerWidth));
  const updateDimensions = useCallback(() => {
    setColumns(getColumns(window.innerWidth));
  }, [getColumns]);

  const { userData } = useMovie();
  const token = userData.access_token;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);
    return () => clearTimeout(handler);
  }, [query]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [updateDimensions]);

  const getMovies = useCallback(
    async (currentPage: number = 1) => {
      setLoading(true);
      const [field, direction] = order.split("-");

      const countriesIds = countries
        .map((slug) => countriesData.find((c) => c.slug === slug)?.id)
        .filter((id): id is string => Boolean(id))
        .join(",");

      const genresIds = genres
        .map((slug) => genresData.find((c) => c.slug === slug)?.id)
        .filter((id): id is string => Boolean(id))
        .join(",");

      const providersIds = providers
        .map((slug) => providersData.find((c) => c.slug === slug)?.id)
        .filter((id): id is string => Boolean(id))
        .join(",");

      try {
        let movies;
        const params = {
          currentPage,
          perPage: 30,
          orderByField: field,
          orderByDirection: direction,
          token,
          search: debouncedQuery,
          certification: certification.join(","),
          providers: providersIds,
          genres: genresIds,
          countries: countriesIds,
        }
        switch (type) {
          case "now-playing":
            movies = await getNowPlayingMovies(params);
            break;
          case "upcoming":
            movies = await getUpcomingMovies(params);
            break;
          case "top-rated":
            movies = await getTopRatedMovies(params);
            break;
          default:
            movies = await getAllMovies(params);
        }
        setResults(movies);
      } catch (error) {
        console.error("Erro ao buscar filmes:", error);
      } finally {
        setLoading(false);
      }
    },
    [type, order, debouncedQuery, certification, providers, genres, countries, token]
  );

  useEffect(() => {
    getMovies(Number(page));
  }, [type, order, debouncedQuery, certification, providers, genres, countries, getMovies, page]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();

    newSearchParams.set("query", query);
    newSearchParams.set("type", type);
    newSearchParams.set("order", order);
    newSearchParams.set("page", page);

    if (certification.length) {
      certification.forEach((cert) => newSearchParams.append("certification", cert));
    }

    if (providers.length) {
      providers.forEach((provider) => newSearchParams.append("providers", provider));
    }

    if (genres.length) {
      genres.forEach((provider) => newSearchParams.append("genres", provider));
    }

    if (countries.length) {
      countries.forEach((provider) => newSearchParams.append("countries", provider));
    }

    setSearchParams(newSearchParams);

  }, [query, type, order, certification, providers, genres, countries, setSearchParams, page]);

  const handleCertificationChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setCertification(typeof value === "string" ? value.split(",") : value);
  };

  const handleProviderChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setProviders(typeof value === "string" ? value.split(",") : value);
  };

  const handleGenreChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setGenres(typeof value === "string" ? value.split(",") : value);
  };

  const handleCountryChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setCountries(typeof value === "string" ? value.split(",") : value);
  };

  const clearFilter = () => {
    setCertification([])
    setProviders([])
    setGenres([])
    setCountries([])
  };

  const hasFilter = () => {
    return certification.length === 0 &&
      providers.length === 0 &&
      genres.length === 0 &&
      countries.length === 0;
  }

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setType("all");
    getMovies(Number(page));
  };

  return (
    <React.Fragment>
      <Title value="Lista de Filmes" />
      <Box sx={BoxPrimaryStyle}>
        <Box sx={BoxSecundaryStyle}>
          <Paper component="form" sx={PaperStyle} onSubmit={handleSearchSubmit}>
            <InputBase
              autoFocus
              sx={{ ml: 1, flex: 1 }}
              placeholder="Procure um filme..."
              inputProps={{ "aria-label": "Procure um filme..." }}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setQuery(event.target.value)
                setPage('1');
              }}
              value={query}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search" color="primary">
              <Search />
            </IconButton>
            {query && (
              <IconButton
                sx={{ p: "10px" }}
                aria-label="clear"
                color="default"
                onClick={() => {
                  setQuery("");
                }}
              >
                <Backspace />
              </IconButton>
            )}
          </Paper>

          <FormControl sx={FormControlStyle} size="small">
            <InputLabel color="primary">Tipo</InputLabel>
            <Select value={type} label="Tipo" onChange={(e) => {
              setType(e.target.value);
              setPage('1');
            }}>
              <MenuItem value="now-playing">
                <FontAwesomeIcon icon={faCalendarDay} /> &nbsp; Em Cartaz
              </MenuItem>
              <MenuItem value="upcoming">
                <FontAwesomeIcon icon={faCalendar} /> &nbsp; Em breve
              </MenuItem>
              <MenuItem value="top-rated">
                <FontAwesomeIcon icon={faHeart} /> &nbsp; Mais Avaliados
              </MenuItem>
              <MenuItem value="all">
                <FontAwesomeIcon icon={faA} style={{ transform: "rotate(180deg)" }} /> &nbsp; Todos
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={FormControlStyle} size="small">
            <InputLabel color="primary">Ordenar</InputLabel>
            <Select value={order} label="Ordenar" onChange={(e) => setOrder(e.target.value)}>
              <MenuItem value="title-ASC">
                <FontAwesomeIcon icon={faArrowDownAZ} /> &nbsp; Título (A-Z)
              </MenuItem>
              <MenuItem value="title-desc">
                <FontAwesomeIcon icon={faArrowDownZA} /> &nbsp; Título (Z-A)
              </MenuItem>
              <MenuItem value="release_date-asc">
                <FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Data de Lançamento (Antigo)
              </MenuItem>
              <MenuItem value="release_date-desc">
                <FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Data de Lançamento (Recente)
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row', }, }}>
          <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 200 }} size="small">
            <InputLabel id="certification">Classificação</InputLabel>
            <Select
              labelId="certification"
              id="certifications"
              multiple
              value={certification}
              onChange={handleCertificationChange}
              input={<OutlinedInput label="Classificação" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {certificationsData.map((data) => (
                <MenuItem key={data} value={data}>
                  <Checkbox checked={certification.includes(data)} />
                  <ListItemText primary={data} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 200 }} size="small">
            <InputLabel id="providers">Streamings</InputLabel>
            <Select
              labelId="providers"
              id="providers"
              multiple
              value={providers}
              onChange={handleProviderChange}
              input={<OutlinedInput label="Streamings" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {providersData.map((data) => (
                <MenuItem key={data.id} value={data.slug}>
                  <Checkbox checked={providers.includes(data.slug)} />
                  <ListItemText primary={data.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 200 }} size="small">
            <InputLabel id="genres">Gêneros</InputLabel>
            <Select
              labelId="genres"
              id="genres"
              multiple
              value={genres}
              onChange={handleGenreChange}
              input={<OutlinedInput label="Gêneros" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {genresData.map((data) => (
                <MenuItem key={data.id} value={data.slug}>
                  <Checkbox checked={genres.includes(data.slug)} />
                  {data.icon || "🎬"}
                  <ListItemText primary={data.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 200 }} size="small">
            <InputLabel id="countries">Países</InputLabel>
            <Select
              labelId="countries"
              id="countries"
              multiple
              value={countries}
              onChange={handleCountryChange}
              input={<OutlinedInput label="Países" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {countriesData.map((country: Country) => (
                <MenuItem key={country.id} value={country.slug}>
                  <Checkbox checked={countries.includes(country.slug)} />
                  <Avatar
                    variant="square"
                    src={`https://flagcdn.com/w40/${country.id.toLowerCase()}.png`}
                    alt={`Bandeira ${country.name}`}
                    sx={{ width: 25, height: 20, marginRight: 2 }}
                  >
                    🌍
                  </Avatar>
                  <ListItemText primary={country.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="contained" endIcon={<RestartAlt />} onClick={clearFilter} disabled={hasFilter()}>
            Limpar
          </Button>
        </Box>

        {loading ? (
          <ImageList gap={1} cols={columns}>
            {Array.from({ length: 24 }, (_, index) => (
              <ImageListItem key={index}>
                <MovieListSkeleton />
              </ImageListItem>
            ))}
          </ImageList>
        ) : result?.data?.length ? (
          <div
            className="grid-container"
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${columns}, 1fr)`,
              gap: "16px",
            }}
          >
            {result.data.map((movie: Movie) => (
              <BoxMovie key={movie.id} movie={movie} />
            ))}
          </div>
        ) : (
          <Typography color="red" sx={{ textTransform: 'capitalize' }}>{query} não encontrado</Typography>
        )}

        <Pagination
          page={Number(page)}
          sx={{ mb: 2 }}
          count={result?.last_page}
          color="primary"
          onChange={(event, page) => {
            setPage(page.toString())
            getMovies(page)
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default MoviesPage;
