/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  ImageList,
  ImageListItem,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material'
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import BoxPerson from '../../components/BoxPerson'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownAZ, faArrowDownZA, faArrowDown19, faArrowDown91, faA } from '@fortawesome/free-solid-svg-icons'
import { Backspace, Search } from '@mui/icons-material'
import { BoxPrimaryStyle, BoxSecundaryStyle, FormControlStyle, PaperStyle } from './style'
import { useMovie } from '../../context/MovieContext'
import ReturnPerson from '../../interfaces/ReturnPerson'
import PersonListSkeleton from '../../components/PersonListSkeleton'
import Title from '../../components/SEO/Title'
import { useSearchParams } from 'react-router-dom'
import { getAllPeople } from '../../api'

export default function PeoplePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true)
  const [query, setQuery] = useState<string>(searchParams.get("query") || "")
  const [debouncedQuery, setDebouncedQuery] = useState<string>("")
  const [result, setResults] = useState<ReturnPerson | undefined>()
  const [type, setType] = useState<string>('all')
  const [order, setOrder] = useState<string>('name-ASC')
  const [adult, setAdult] = useState<boolean>(false)
  const [page, setPage] = useState<string>(searchParams.get("page") || '1')
  const { userData } = useMovie()
  const token = userData.access_token;

  const getPeople = useCallback(
    async (currentPage: number = 1) => {
      setLoading(true);
      const [field, direction] = order.split("-");

      try {
        let people;
        const params = {
          currentPage,
          perPage: 30,
          orderByField: field,
          orderByDirection: direction,
          token,
          search: debouncedQuery,
          adult: adult
        }
        people = await getAllPeople(params);
        setResults(people);
      } catch (error) {
        console.error("Erro ao buscar filmes:", error);
      } finally {
        setLoading(false);
      }
    },
    [type, order, debouncedQuery, token, adult]
  );

  const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
  const getColumns = (width: any) => {
    if (width < breakpoints.sm) {
      return 2
    } else if (width < breakpoints.md) {
      return 4
    } else if (width < breakpoints.lg) {
      return 6
    } else if (width < breakpoints.xl) {
      return 8
    } else {
      return 10
    }
  }
  const [columns, setColumns] = useState(getColumns(window.innerWidth))
  const updateDimensions = () => {
    setColumns(getColumns(window.innerWidth))
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);
    return () => clearTimeout(handler);
  }, [query]);

  useEffect(() => {
    getPeople()
  }, [type, order, debouncedQuery, token, adult])

  useEffect(() => {
    const newSearchParams = new URLSearchParams();

    newSearchParams.set("query", query);
    newSearchParams.set("type", type);
    newSearchParams.set("order", order);
    newSearchParams.set("page", page);
    newSearchParams.set("adult", String(adult));

    setSearchParams(newSearchParams);

  }, [query, type, order, setSearchParams, page, adult]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setType('all')
    getPeople()
  }

  return (
    <>
      <Title value='Lista de Pessoas' />
      <Box sx={BoxPrimaryStyle}>
        <Box sx={BoxSecundaryStyle}>
          <Paper component='form' sx={PaperStyle} onSubmit={handleSubmit}>
            <InputBase
              autoFocus
              sx={{ ml: 1, flex: 1 }}
              placeholder='Procure uma pessoa...'
              inputProps={{ 'aria-label': 'Procure uma pessoa...' }}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setQuery(event.target.value)
                setPage('1');
              }}
              value={query}
            />
            <IconButton type='submit' sx={{ p: '10px' }} aria-label='search' color='primary'>
              <Search />
            </IconButton>
            {
              query &&
              <IconButton
                sx={{ p: '10px' }}
                aria-label='search'
                color='default'
                onClick={() => {
                  setQuery('')
                  // setType('popular')
                }}
              >
                <Backspace />
              </IconButton>
            }
          </Paper>

          <FormControl sx={FormControlStyle} size='small' disabled={query ? true : false}>
            <InputLabel color='primary'>Tipo</InputLabel>
            <Select
              value={type}
              label='Tipo'
              onChange={(event: SelectChangeEvent) => setType(event.target.value)}
            >
              <MenuItem value='all'><FontAwesomeIcon icon={faA} style={{ transform: 'rotate(180deg)' }} /> &nbsp; Todos</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={FormControlStyle} size='small'>
            <InputLabel color='primary'>Ordenar</InputLabel>
            <Select
              value={order}
              label='Ordenar'
              onChange={(event: SelectChangeEvent) => setOrder(event.target.value)}
            >
              <MenuItem value='name-ASC'><FontAwesomeIcon icon={faArrowDownAZ} /> &nbsp; Nome</MenuItem>
              <MenuItem value='name-DESC'><FontAwesomeIcon icon={faArrowDownZA} /> &nbsp; Nome</MenuItem>
              <MenuItem value='birthday-ASC'><FontAwesomeIcon icon={faArrowDown19} /> &nbsp; Data de Nascimento</MenuItem>
              <MenuItem value='birthday-DESC'><FontAwesomeIcon icon={faArrowDown91} /> &nbsp; Data Nascimento</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={FormControlStyle} size='small'>
            <FormControlLabel control={<Switch
              defaultChecked={false}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setAdult(event.target.checked)} />
            } label="+18" />
          </FormControl>
        </Box>
        {
          result?.data && loading === false ?
            <ImageList gap={1} cols={columns}>
              {result.data.map((person) => {
                const profilePath = person?.profile_path;
                return (
                  <ImageListItem key={person.id}>
                    <BoxPerson id={person.id} name={person.name} slug={person.slug} profile_path={profilePath} />
                    <Divider />
                  </ImageListItem>
                )
              })}
            </ImageList> :
            <ImageList gap={1} cols={columns}>
              {
                Array(60).fill(null).map((_, index) =>
                  <ImageListItem key={index}>
                    <PersonListSkeleton />
                  </ImageListItem>
                )
              }
            </ImageList>
        }
        {
          result?.total === 0 ? <Typography color="red" sx={{ textTransform: 'capitalize' }}>{query} não encontrado</Typography> :
            <Pagination
              sx={{ mb: 2 }}
              count={result?.last_page}
              color='primary'
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                setPage(page.toString())
                getPeople(page)
              }}
            />
        }
      </Box>
    </>
  )
}
