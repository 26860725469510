import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";

const TmdbRating = ({ value }: { value: number; }) => {

  return (
    <Tooltip title="Classificação Geral dos Utilizadores">
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={value}
          size={60}
          thickness={4.5}
          sx={{
            color: "#21d07a",
            backgroundColor: '#081c22',
            borderRadius: '100%'
          }}
        />
        <CircularProgress
          variant="determinate"
          value={100}
          size={60}
          thickness={4.5}
          sx={{
            color: "rgba(32, 69, 41, 0.5)",
            position: "absolute",
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="body2"
            component="div"
            sx={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default TmdbRating;
