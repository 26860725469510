import MovieListSkeleton from '../../components/MovieListSkeleton'
import SingleLineImageList from '../../components/SingleLineImageList'
import { useMovie } from '../../context/MovieContext'
import IDisplayMovies from '../../interfaces/DisplayMovies'
import Title from '../../components/SEO/Title'
import { Box, Button, useMediaQuery } from '@mui/material'
import BoxMovie from '../../components/BoxMovie'
import { KeyboardArrowRight } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getNowPlayingMovies, getTopRatedMovies, getUpcomingMovies } from '../../api'

interface ISkeletonProps {
  skeletonKey: string
}

interface IDisplayProps {
  id: string
  data: IDisplayMovies
}

export default function HomePage() {
  const navigate = useNavigate()
  const [nowPlaying, setNowPlaying] = useState<IDisplayMovies | null>(null)
  const [upcoming, setUpcoming] = useState<IDisplayMovies | null>(null)
  const [topRated, setTopRated] = useState<IDisplayMovies | null>(null)
  const { userData, removeUserData } = useMovie()
  
  useEffect(() => {
    (async () => {
      const token = userData && typeof userData === 'object' ? userData.access_token : null
      if (!nowPlaying) {
        getNowPlayingMovies({
          orderByField: 'release_date',
          orderByDirection: 'desc',
          token,
        })
          .then(async (movies) => {
            setNowPlaying({
              title: 'Filmes em cartaz', movies: movies.data, lastPage: movies.last_page
            })
          })
          .catch((err) => {
            console.error(err)
            if (err.response.status === 401) {
              removeUserData()
              setNowPlaying(null)
              setUpcoming(null)
              setTopRated(null)
            }
          })
      }
      if (!upcoming) {
        getUpcomingMovies({
          orderByField: 'release_date',
          orderByDirection: 'asc',
          token,
        })
          .then(async (movies) => {
            setUpcoming({
              title: 'Filmes em breve', movies: movies.data, lastPage: movies.last_page
            })
          })
          .catch((err) => {
            console.error(err)
            if (err.response.status === 401) {
              removeUserData()
              setNowPlaying(null)
              setUpcoming(null)
              setTopRated(null)
            }
          })
      }
      if (!topRated) {
        getTopRatedMovies({
          orderByField: null,
          orderByDirection: 'asc',
          token,
        })
          .then(async (movies) => {
            setTopRated({
              title: 'Filmes mais avaliados', movies: movies.data, lastPage: movies.last_page
            })
          })
          .catch((err) => {
            console.error(err)
            if (err.response.status === 401) {
              removeUserData()
              setNowPlaying(null)
              setUpcoming(null)
              setTopRated(null)
            }
          })
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const DisplayMovies = ({ id, data }: IDisplayProps) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    return (
      <SingleLineImageList key={id} title={data.title}>
        {
          data.movies.map((movie, index) => (
            <Box
              key={index}
              sx={{
                minWidth: isSmallScreen ? '150px' : '220px',
                flexShrink: 0,
              }}
            >
              <BoxMovie movie={movie} />
            </Box>
          ))
        }
        {data.lastPage > 1 && <Button endIcon={<KeyboardArrowRight />} onClick={() => navigate(`/filmes?type=${id}&page=2`)}>Mais</Button>}
      </SingleLineImageList>
    )
  }
  const SkeletonDisplayMovies = ({ skeletonKey }: ISkeletonProps) => {
    return (
      <SingleLineImageList title='' key={skeletonKey}>
        {
          Array(10).fill(null).map((value, index) =>
            <MovieListSkeleton key={index} />
          )
        }
      </SingleLineImageList>
    )
  }

  return (
    <>
      <Title value='Tudo o que você ama, em um só lugar!' />
      {
        nowPlaying !== null ?
          <DisplayMovies id='now-playing' data={nowPlaying} /> :
          <SkeletonDisplayMovies skeletonKey='now-playing' />
      }
      {
        topRated !== null ?
          <DisplayMovies id='top-rated' data={topRated} /> :
          <SkeletonDisplayMovies skeletonKey='top-rated' />
      }

      {
        upcoming !== null ?
          <DisplayMovies id='upcoming' data={upcoming} /> :
          <SkeletonDisplayMovies skeletonKey='upcoming' />
      }
    </>
  )
}
