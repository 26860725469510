import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BoxImageNotLoadedStyle, BoxImageStyle, BoxStyle, TypographyStyle, TypographySubTitleStyle } from './style'
interface Props {
  id: string
  slug: string
  name: string
  profile_path?: {
    tmdb: string
  }
  character?: string 
  job?: string 
  department?: string 
}

export default function BoxPerson({ id, slug, name, character, profile_path, job, department }: Props) {
  const navigate = useNavigate()
  const [load, setLoad] = useState<boolean>(false)
  const stylePoster = load ? BoxImageStyle : { display: 'none' }

  return (
    <Box key={id} sx={BoxStyle}>
      {
        !load &&
        <Box
          sx={BoxImageNotLoadedStyle}
          key={slug}
          component='img'
          src='./../img/not_loaded.png'
          alt={`Foto de ${name}`}
          title={`Foto de ${name} não carregou`}
          width='120px'
          onClick={() => navigate(`/pessoas/${slug}`)}
        />
      }
      <Box
        sx={stylePoster}
        key={id}
        component='img'
        alt={name}
        src={profile_path?.tmdb ? `https://www.themoviedb.org/t/p/w220_and_h330_face/${profile_path.tmdb}` : './../img/not_loaded.png'}
        onClick={() => navigate(`/pessoas/${slug}`)}
        onLoad={() => setLoad(true)}
      />
      <Typography
        sx={TypographyStyle}
        component='span'
        variant='subtitle1'
        align='center'
      >
        {name}
      </Typography>
      <Typography
        sx={TypographySubTitleStyle}
        component='span'
        variant='caption'
      >
        {character ?? job}
      </Typography>
      <Typography
        sx={TypographySubTitleStyle}
        component='span'
        variant='caption'
      >
        {department}
      </Typography>
    </Box>
  )
}
