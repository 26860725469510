/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  Skeleton,
  Tab,
  Tabs,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { getPerson, getPersonMovies } from '../../api'
import Person from '../../interfaces/Person'
import { Box, useMediaQuery } from '@mui/system'
import { Ballot, RecentActors, SupervisorAccount } from '@mui/icons-material'
import BoxItem from '../../components/BoxItem'
import Moment from 'react-moment'
import SingleLineImageList from '../../components/SingleLineImageList'
import { BasicMovie } from '../../interfaces/Movie'
import BoxMovie from '../../components/BoxMovie'
import Cast from '../../interfaces/Cast'
import Crew from '../../interfaces/Crew'
import MovieListSkeleton from '../../components/MovieListSkeleton'
import Title from '../../components/SEO/Title'
import FacebookOpenGraph from '../../components/SEO/FacebookOpenGraph'
import LdJson from '../../components/SEO/LdJson'
import { useMovie } from '../../context/MovieContext'
import CharacteresTab from './CharacteresTab'
import CreditsTab from './CreditsTab'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export default function PersonPage() {
  let { path } = useParams()
  const [person, setPerson] = useState<Person | null>(null)
  const [cast, setCast] = useState<Cast[]>()
  const [crew, setCrew] = useState<Crew[]>()
  const [movies, setMovies] = useState<BasicMovie[]>([])
  const [moviesIsLoading, setMoviesIsLoading] = useState<boolean>(true)
  const { userData } = useMovie()

  useEffect(() => {
    (async () => {
      if (path) {
        const result = await getPerson(path)
        setPerson(result)

        setCast(result.cast_movies)

        setCrew(result.crew_movies)

        setMoviesIsLoading(false)

        const resultMovies = await getPersonMovies(result.id, userData.access_token)
        setMovies(resultMovies)
      }
    })()
  }, [path])
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, value: number) => {
    setValue(value)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <>
      {
        person &&
        <>
          <Title value={person.name} />
          <FacebookOpenGraph title={person.name} id={person.id} slug={person.slug} imagePath={person.profile_path?.tmdb} type='person' />
          <LdJson data={person} />
        </>
      }
      <Tabs centered value={value} onChange={handleChange} aria-label='icon label tabs example'>
        <Tab
          icon={<Ballot />}
          label='SOBRE'
        />
        <Tab
          icon={<SupervisorAccount />}
          label='Personagens'
        />
        <Tab
          icon={<RecentActors />}
          label='Créditos'
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {
              person ?
                <Box
                  sx={{
                    height: ['250px', '200px'],
                    width: ['183,33px', '133.33px'],
                    margin: '2px'
                  }}
                  component='img'
                  alt={`Foto de ${person?.name}`}
                  src={`https://www.themoviedb.org/t/p/w220_and_h330_face/${person?.profile_path?.tmdb}`}
                /> :
                <Skeleton width={133.33} height={200} variant="rectangular" animation="wave" sx={{ margin: '2px' }} />
            }
          </Box>
          <CardContent sx={{ width: '100%' }}>
            {
              person ?
                <>
                  <BoxItem label='Nome' text={person?.name} />
                  <BoxItem label='Conhecido(a) por' text={person?.known_for_department} />
                  <BoxItem label='Data de nascimento' text={person?.birthday && <Moment format='DD/MM/YYYY'>{person.birthday}</Moment>} />
                  {person?.deathday && <BoxItem label='Data de falecimento' text={<Moment format='DD/MM/YYYY'>{person.deathday}</Moment>} />}
                  <BoxItem label='Altura' text={person?.height} />
                  <BoxItem label='Peso' text={person?.weight} />
                  <BoxItem label='Biografia' text={person?.biography} />
                </> :
                Array(4).fill(null).map((_, index) => <Skeleton key={index} width={600} height={20} variant="rectangular" sx={{ marginTop: '4px' }} />)
            }
          </CardContent>
        </Card>
        {
          moviesIsLoading ?
            Array(1).fill(null).map((_, index) =>
              <SingleLineImageList title='' key={index}>
                {
                  Array(6).fill(null).map((_, index) =>
                    <MovieListSkeleton key={index} />
                  )
                }
              </SingleLineImageList>
            ) :
            <SingleLineImageList key='Filmografia' title='Filmografia'>
              {
                movies && movies.length !== 0 && movies.map((movie, index) => (
                  <Box
                    key={index}
                    sx={{
                      minWidth: isSmallScreen ? '150px' : '220px',
                      flexShrink: 0,
                    }}
                  >
                    <BoxMovie movie={movie} />
                  </Box>
                ))
              }
            </SingleLineImageList>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {cast && <CharacteresTab title='Personagens' cast={cast} />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {crew && <CreditsTab title='Créditos' crew={crew} />}
      </TabPanel>
    </>
  )
}
